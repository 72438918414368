
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





























































































.autocomplete {
  position: relative;
}

.autocomplete-results {
  margin: 0;
  border: 1px solid #eeeeee;
  height: 130px;
  overflow: auto;
  position: absolute;
  width: 400px;
  z-index: 100;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  top: 2.3em;
  left: 14rem;
  padding: 10px 20px;

  @media screen and (max-width: 960px) {
    width: 320px;
    top: 4.3em;
    left: 0;
  }
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
