
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


















































































































































































































































.form-block {
  max-width: 29rem;
  @include mq(xs) {
    max-width: 49rem;
  }

  .h3 {
    color: $c-green;
    font-size: 1.8rem;
    margin: 3rem 0;
  }
}

.nonvalide{
  color: #E84E0F;
  font-weight: 700;
}

.select.label {
  display: block;
}

.select select {
  width: 100%;
  padding: 0.5rem 0 0.3rem;
  font-size: 1.8rem;
  border: 0;
}

.feedback-message {
  width: 100%;
}

.phone-warning {
  background: #f8cab7;
}

.loading {
  margin-left: 1.5rem;
}

.phone {
  display: flex;
  width: 100%;
  color: $c-gray;

  ::v-deep .input {
    width: 100%;
  }
}

.phone__flag {
  flex-shrink: 0;
  width: 2rem;
  margin-right: 0.5rem;
}

.country-codes {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1rem;
  padding: 0.5rem;
  border-bottom: 1px solid $c-gray-lighter;

  ::v-deep .placeholder {
    justify-content: flex-end;
  }

  ::v-deep .options {
    width: 9rem;
  }
}
.customLabel {
  font-size: 3em;
}
.select.label {
  display: block;
}

.select {
  @extend %fw-medium;
  // prettier-ignore
  @include fluid(font-size, (xxs: 11px, m: 13px));

  color: $c-orange;
  font-weight: 300;
  border-bottom: 1px solid $c-gray-lighter;

  ::v-deep {
    svg {
      fill: $c-main-primary;
    }

    .vs__dropdown-toggle {
      width: 100%;
      padding: 0.5rem 0 0.3rem;
      font-size: 1.8rem;
      border: 0;
    }

    .vs__dropdown-menu {
      margin-top: 0.5rem;
      padding: 0.5rem 0 0.3rem;
      font-size: 1.8rem;
    }

    .vs__search,
    .vs__actions,
    .vs__selected,
    .vs__selected-options {
      margin: 0;
      padding: 0;
      border: 0;
    }

    .vs__actions {
      line-height: 1;
    }

    .vs__clear {
      margin-bottom: 0.3rem;
    }
  }
}

.feedback-message {
  width: 100%;
}

.phone-warning {
  background: #f8cab7;
}

.loading {
  margin-left: 1.5rem;
}

.phone {
  display: flex;
  width: 100%;
  color: $c-gray;

  ::v-deep .input {
    width: 100%;
  }
}

.phone__flag {
  flex-shrink: 0;
  width: 2rem;
  margin-right: 0.5rem;
}

.country-codes {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1rem;
  padding: 0.5rem;
  border-bottom: 1px solid $c-gray-lighter;

  ::v-deep .placeholder {
    justify-content: flex-end;
  }

  ::v-deep .options {
    width: 9rem;
  }
}
.switcher {
  ::v-deep {
    margin-top: -0.7em;

    @media screen and (max-width: 720px) {
      margin-left: 0 !important;
      margin-top: 1em;
    }
  }
}
.submitForm {
  @include loading($c-green);
}
