
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






















.select-box {
  position: relative;

  &.--inline-block {
    @include mq(m) {
      display: inline-block;
    }
  }

  &.--panel-right {
    .select-box__modal {
      right: 0;
      left: unset;
    }
  }

  &.is-open &__title {
    border: 1px solid $c-gray-darker;
  }

  &.is-open &__modal {
    opacity: 1;
    visibility: visible;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.6rem;
    padding: 0 1.5rem;
    font-size: 1.4rem;
    border: 1px solid $c-gray;
    border-radius: 0.5rem;
    cursor: pointer;

    &:hover {
      border: 1px solid $c-gray-darker;
    }

    > svg {
      width: 1em;
      margin-left: 2rem;
    }

    .icon {
      transition: transform 0.25s;

      .select-box.is-open & {
        transform: scale(-1);
      }
    }
  }

  &__modal {
    visibility: hidden;
    opacity: 0;
    margin-top: 0.5rem;
    position: absolute;
    left: 0;
    z-index: 1;
    background-color: $c-white;
    border-radius: 0.5rem;
    border: 1px solid $c-gray-light;
    padding: 2rem;
    width: 100%;
    box-shadow: 0 1rem 3rem rgba($c-black, 0.1);
    transition: all 0.3s ease-in-out;

    @include mq(m) {
      width: 280px;
    }

    button {
      margin-top: 2rem;
      border: 0.5rem;
    }
  }

  &__items {
    display: flex;
    flex-flow: column;
    max-height: 30rem;
    overflow-x: auto;
  }

  &__item {
    flex: 0 0 auto;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;

    input[type='checkbox'] {
      margin: 0;
      height: 2rem;
      width: 2rem;
      flex: 0 0 auto;
    }

    &.--checkbox-orange {
      @include custom-checkbox();
    }
  }
}

.selector {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  margin-bottom: 4rem;

  @include mq(m) {
    flex-flow: row wrap;
  }

  .select-box {
    @include mq(m) {
      margin-left: 0.5rem;
      margin-bottom: unset;
    }
    flex-flow: row wrap;
    margin-bottom: 1rem;
  }
}
